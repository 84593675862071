button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  // width: 20px;
  height: 100%;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 3%;
  z-index: 2;
  opacity: 0.5;
}
.slick-prev {
  left: 0;
  &::before {
    content: "\f053";
    font-weight: 600;
    font-family: "Font Awesome\ 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: black;
    font-size: 40px;
    width: 100%;
  }
}
.slick-next {
  right: 0;
  &::before {
    content: "\f054";
    font-weight: 600;
    font-family: "Font Awesome\ 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: black;
    font-size: 40px;
    width: 100%;
  }
}
